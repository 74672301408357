<template>
  <a-config-provider prefix-cls="sat">
    <a-layout class="layout-wrapper">
      <a-layout-header class="position-fixed w-100 bg-light shadow px-3" style="z-index: 2;">
        <div class="d-flex align-items-center justify-content-between">
          <div class="logo d-flex align-items-center">
            <router-link to="/">
              <img class="me-3 mb-1" src="./assets/images/rugbyLogo.png" height="50" />
             <span class="h6 mb-0 nameLogo text-dark">สมาคมกีฬารักบี้แห่งประเทศไทย</span>
            </router-link>
          </div>
          <div v-if="!token" class="d-none">
            <a-button class="shadow-sm me-1 fw-bold" size="large" @click="()=>visibleModal.signIn = true">
              <template #icon><IconSVG name="signIn"/></template><span class="f-roboto">Sign In</span>
            </a-button>
            <a-button class="shadow-sm fw-bold" size="large" @click="()=>visibleModal.signUp = true">
              <template #icon><IconSVG name="signUp"/></template><span class="f-roboto">Sign Up</span>
            </a-button>
          </div>
          <div v-else class="d-flex align-items-center">
            <a-button class="shadow-sm me-1 fw-bold" :class="{'d-none': !ableToManageMember || !['home', 'forgot-password'].includes($route.name)}" size="large" @click="$router.push({name: 'member-management'})">
              <template #icon>
                <IconSVG name="userManagement" style="vertical-align: 2px; font-size: 1.25rem;"/>
              </template>
              <span class="lh-1 f-roboto" style="font-size: 0.9em;">Member<br/>Management</span>
            </a-button>
            <a-popover trigger="hover" placement="bottomRight" overlayClassName="user-btn-menu">
              <template #content>
                <ul class="list-unstyled mb-0 f-roboto">
                  <li class="border-bottom">
                    <a-button type="default" class="fw-bold border-0" @click="$router.push({name: 'update-password'})">Reset password</a-button>
                  </li>
                  <li>
                    <a-button type="default" class="fw-bold border-0" @click="()=>visibleModal.signOut = true">Sign out</a-button>
                  </li>
                </ul>
              </template>
              <a-button size="large">
                <template #icon><IconSVG name="signOut" style="font-size: 1.5em;"/></template><IconSVG name="downArrow" />
              </a-button>
            </a-popover>
            <!-- <a-button class="shadow-sm fw-bold" size="large" @click="()=>visibleModal.signOut = true">
              <template #icon><IconSVG name="signOut" style=""/></template><span class="f-roboto">Sign Out</span>
            </a-button> -->
          </div>
        </div>
      </a-layout-header>
      <a-layout-content id="app-layout" style="padding-top: 64px">
        <router-view />
      </a-layout-content>
    </a-layout>
    <a-modal
      :width="350"
      centered
      v-model:visible="visibleModal.signIn"
      :footer="null"
      :closable="false"
    >
      <h3 class="text-center">Sign In</h3>
      <a-form
        layout="vertical"
        ref="signInFormRef"
        :model="signInFormState"
        :rules="signInRules"
        @finish="handleSignInFinish"
        >
        <a-form-item label="E-mail" required has-feedback name="email">
          <a-input v-model:value="signInFormState.email" placeholder="E-mail Address" type="email" />
        </a-form-item>
        <a-form-item label="Password" required has-feedback name="password">
          <a-input v-model:value="signInFormState.password" placeholder="Password" type="password" autocomplete="off" />
        </a-form-item>
        <a-form-item>
          <div class="d-flex justify-content-center">
            <a-button type="primary" html-type="submit">Submit</a-button>
            <a-button style="margin-left: 10px" @click="closeSignInModal">Cancel</a-button>
          </div>
        </a-form-item>
      </a-form>
      <a-divider><span class="small">Don't have an account? <a @click="openSignUpModal">Sign Up</a></span></a-divider>
    </a-modal>
    <a-modal
      :width="350"
      centered
      v-model:visible="visibleModal.signUp"
      :footer="null"
      :closable="false"
    >
      <h3 class="text-center">Sign Up</h3>
      <a-form
        layout="vertical"
        ref="signUpFormRef"
        :model="signUpFormState"
        :rules="signUpRules"
        @finish="handleSignUpFinish"
        >
        <a-form-item label="E-mail" required has-feedback name="email">
          <a-input v-model:value="signUpFormState.email" placeholder="E-mail Address" type="email" />
        </a-form-item>
        <a-form-item label="Password" required has-feedback name="password">
          <a-input v-model:value="signUpFormState.password" placeholder="Password" type="password" autocomplete="off" />
        </a-form-item>
        <a-form-item label="Confirm Password" required has-feedback name="confirmPassword">
          <a-input v-model:value="signUpFormState.confirmPassword" placeholder="Confirm Password" type="password" autocomplete="off" />
        </a-form-item>
        <a-form-item>
          <div class="d-flex justify-content-center">
            <a-button type="primary" html-type="submit">Submit</a-button>
            <a-button style="margin-left: 10px" @click="closeSignUpModal">Cancel</a-button>
          </div>
        </a-form-item>
      </a-form>
    </a-modal>
    <AlertModal v-model:visible="visibleModal.alert" :message="alertMessage" @close-alert-modal="visibleModal.alert = false" />
    <a-modal
      v-model:visible="visibleModal.signOut"
      :footer="null"
      :closable="false"
    >
    <div class="container">
      <div class="d-flex align-items-center mb-5">
        <InfoCircleOutlined class="me-3 text-warning fs-4" /><span class="fs-6">Do you want to sign out of the system?</span>
      </div>
      <div class="d-flex justify-content-end">
        <div>
          <a-button style="margin-right: 10px" @click="()=> visibleModal.signOut = false">Cancel</a-button>
          <a-button type="primary" @click="signOut">OK</a-button>
        </div>
      </div>
    </div>
    </a-modal>
  </a-config-provider>
</template>

<script lang="ts">
import { defineComponent, reactive, ref, UnwrapRef, watchEffect } from "vue";
import { useRouter } from 'vue-router'
import { RuleObject } from 'ant-design-vue/es/form/interface';
import { InfoCircleOutlined } from '@ant-design/icons-vue';
import "ant-design-vue/es/modal/style"
import { useAuth } from "@/_modules/auth"
import IconSVG from "@/components/IconSVG.vue";
import AlertModal from "@/components/AlertModal.vue";
interface IVisibleModal {
  signIn: boolean
  signUp: boolean
  alert: boolean
  signOut: boolean
}
interface SignInFormState {
  email: string;
  password: string;
}
interface SignUpFormState extends SignInFormState{
  confirmPassword: string;
}
export default defineComponent({
  components: {
    IconSVG,
    AlertModal,
    InfoCircleOutlined,
    // DownOutlined
  },
  setup() {
    const router = useRouter()
    const { token, account, logout } = useAuth();
    const ableToManageMember = ref<boolean>(false)
    watchEffect(() => {
      const accountRoles = account.value?.account_roles ?? null
      if(accountRoles){
        ableToManageMember.value = accountRoles.some(ele => ele.roles_permission.permissions.permission_menu.application_management_edit === true)
        // console.log('ableToManageMember', ableToManageMember.value)
      }
    })
    const signInFormRef = ref();
    const signUpFormRef = ref();
    const signObj = {
      email: '',
      password: '',
    }
    const signInFormState: UnwrapRef<SignInFormState> = reactive(signObj);
    const signUpFormState: UnwrapRef<SignUpFormState> = reactive({...signObj, confirmPassword: ''});
    const visibleModal = reactive<IVisibleModal>({
      signIn: false,
      signUp: false,
      alert: false,
      signOut: false
    });
    const alertMessage = ref<string>('')
    const signInRules = {
      email: [
        { required: true, message: 'Please input your e-mail address', trigger: 'blur' },
        {type: 'email', message: 'Please input an e-mail address format', trigger: 'blur'}
        //{ min: 3, max: 5, message: 'Length should be 3 to 5', trigger: 'blur' },
      ],
      password: [{required: true, message: 'Please input your password', trigger: 'blur'}]
    }
    //watchEffect(() => console.log(count.value))
    let validatePassword = async (rule: RuleObject, value: string) => {
      if (value === '') {
        return await Promise.reject('Please input the password');
      } else {
        if (signUpFormState.password !== '') {
          signUpFormRef.value.validateField('confirmPassword');
        }
        return await Promise.resolve();
      }
    };
    let validateConfirmPassword = async (rule: RuleObject, value: string) => {
      if (value === '') {
        return await Promise.reject('Please input the password again');
      } else if (value !== signUpFormState.password) {
        return await Promise.reject("Two inputs don't match!");
      } else {
        return await Promise.resolve();
      }
    };
    const signUpRules = {
      ...signInRules,
      password: [{ validator: validatePassword, trigger: 'change' }],
      confirmPassword: [{ validator: validateConfirmPassword, trigger: 'change' }]
    }
    const closeSignInModal = () => {
      visibleModal.signIn = false;
      signInFormRef.value.resetFields()
    }
    const closeSignUpModal = () => {
      visibleModal.signUp = false;
      signUpFormRef.value.resetFields()
    }
    const handleSignInFinish = (values: SignInFormState) => {
      alertMessage.value = 'Coding is my job!'
      // visibleModal.alert = true
      console.log(values, signInFormState, visibleModal);
      // const { setUser } = useAuth()
      // setUser({
      //   email: values.email,
      //   id: 'test',
      //   [AUTH_TOKEN]: 'anyauthtoken'
      // }, true)
      // console.log('userValue', user?.value)
      closeSignInModal()
    }
    const openSignUpModal = () => {
      closeSignInModal()
      setTimeout(()=>{
        visibleModal.signUp = true
      }, 500)
    }
    const handleSignUpFinish = (values: SignUpFormState) => {
      console.log(values, signUpFormState);
      closeSignUpModal()
    }

    const signOut = () => {
      logout()
      visibleModal.signOut = false
      router.replace({ name: 'login' })
    }

    return {
      visibleModal,
      signInFormState,
      signInRules,
      signInFormRef,
      closeSignInModal,
      handleSignInFinish,
      openSignUpModal,
      closeSignUpModal,
      handleSignUpFinish,
      signUpFormState,
      signUpFormRef,
      signUpRules,
      alertMessage,
      token,
      signOut,
      ableToManageMember
    }
  }
})
</script>

<style lang="scss">
#app {
  //font-family: Roboto, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;

}
.sat-popover.user-btn-menu{
  .sat-popover-inner-content{
    padding: 0;
    ul > li {
      padding: 5px;

    }
  }
}

@media (max-width: 601px) {
  .nameLogo {
    display: none;
  }
}

</style>
